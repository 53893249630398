import { IDropdownOption, IModalStyles } from '@fluentui/react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../common/ui/Button/PrimaryButton';
import Dropdown from '../../common/ui/Dropdown/Dropdown';
import { renderControlFunctions } from '../../common/ui/FilterControls/FilterControls';
import { LinkButton } from '../../common/ui/LinkButton/LinkButton';
import CommonModal from '../../common/ui/Modal/CommonModal';
import { OtherOptionWithInputKey } from '../../common/ui/MultipleSelectionField/MultipleSelectionField';
import TextField from '../../common/ui/TextField/TextField';
import { NumberEquipmentSizeConstants, OrganizationSizeConstants } from '../../constants/company.constants';
import { NumberEquipmentSizeEnum, OrganizationSizeEnum } from '../../enums/company.enum';
import { FilterControl } from '../../enums/filter-control.enum';
import { ContactSurveyFormModel } from '../../models/contact-survey-form.model';
import { IFilterItem } from '../../models/filter-controls/filter-item.model';
import { ISearchField } from '../../models/filter-controls/search-field.model';
import { CompanyExpressContext } from '../../pages/Layout/MainLayout';
import { CommonService } from '../../services/common.service';
import { CompanyService } from '../../services/company.service';
import { getCompanyLogo } from '../../utils/img.utils';
import { getLanguageId } from '../../utils/localStorage.utils';

type Props = {
  onDismiss: () => void;
};

const modalStyle: IModalStyles = {
  root: undefined,
  main: {
    borderRadius: '7px',
    width: 'fit-content',
  },
  scrollableContent: undefined,
  layer: undefined,
  keyboardMoveIconContainer: undefined,
  keyboardMoveIcon: undefined,
};

type KeyOfSurveyModel = keyof ContactSurveyFormModel;

const TellUsSurvey = ({ onDismiss }: Props) => {
  const [translate] = useTranslation();
  const [surveyModel, setSurveyModel] = useState<ContactSurveyFormModel>({
    contactId: 0,
    companyId: 0,
    yourRole: '',
    companySize: undefined,
    equipmentSize: undefined,
    industries: undefined,
    otherIndustry: undefined,
  });
  const [submitted, setSubmitted] = useState(false);

  const { info } = useContext(CompanyExpressContext);
  const { companyExpressSetup } = info;

  const orgSizeOptions = useMemo(() => {
    const employeesCaption = translate('Company.employees');
    const options = Object.keys(OrganizationSizeEnum)
      .map((key: string) => {
        const keyConverted = Number.parseInt(key) as OrganizationSizeEnum;
        const text = `${OrganizationSizeConstants[keyConverted]} ${employeesCaption}`;

        return {
          key: keyConverted,
          title: text,
          text: text,
        } as IDropdownOption;
      })
      .filter((m) => !Number.isNaN(m.key));

    return options;
  }, []);

  const numberEquipmentSizeOptions = useMemo(() => {
    const equipmentCaption = translate('CaptionResource.Caption1').toLowerCase();
    const options = Object.keys(NumberEquipmentSizeEnum)
      .map((key: string) => {
        const keyConverted = Number.parseInt(key) as NumberEquipmentSizeEnum;
        const text = `${NumberEquipmentSizeConstants[keyConverted]} ${equipmentCaption}`;

        return {
          key: keyConverted,
          title: text,
          text: text,
        } as IDropdownOption;
      })
      .filter((m) => !Number.isNaN(m.key));

    return options;
  }, []);

  const logoModel = {
    imageInfoId: companyExpressSetup?.logoImageInfoId,
    imageContent: companyExpressSetup?.logoImageContent,
    companyLogo: companyExpressSetup?.companyLogo,
  };

  const industryField: ISearchField = useMemo(() => {
    return {
      key: 'industry-field',
      type: 0,
      control: FilterControl.DROPDOWN_MULTI_WITHSEARCH,
      label: 'CaptionResource.WhichIndustryYouWork',
      panelTitle: 'CaptionResource.Industry',
      required: true,
      options: [],
      value: 0,
      errorMessage: !surveyModel.industries && !surveyModel.otherIndustry && submitted ? translate('CaptionResource.Required') : '',
      selectAllPermission: false,
      maxNumberSelection: 3,
      otherOptionWithInput: {
        maxLength: 50,
        required: true,
      },
    };
  }, [surveyModel.industries, surveyModel.otherIndustry, submitted, translate]);

  const searchIndustryList = async (searchText = '') => {
    const result: IFilterItem[] = [];
    const data = await CompanyService.searchIndustryList(searchText, getLanguageId());
    if (data) {
      data.forEach((item) => {
        result.push({
          key: item.industryId,
          text: item.description,
          title: item.description,
        });
      });
    }
    result.push({
      key: OtherOptionWithInputKey,
      text: translate('CommonResource.lblOther'),
      title: translate('CommonResource.lblOther'),
    });
    return result;
  };

  const renderIndustryField = useCallback(() => {
    const EquipmentFilterControl = renderControlFunctions[industryField.control];
    if (EquipmentFilterControl !== undefined) {
      return (
        <div>
          <EquipmentFilterControl
            key={industryField.key}
            field={industryField}
            label={industryField.label}
            value={industryField.value}
            onSearch={searchIndustryList}
            onDataChanged={() => {
              const newIndustry = industryField.options
                ?.filter((x: any) => x.id !== OtherOptionWithInputKey)
                .map((m: any) => m.id)
                .join(',');
              updateSurveyModel('industries', newIndustry);
              updateSurveyModel('otherIndustry', industryField.otherOptionWithInput?.inputText);
            }}
          />
        </div>
      );
    }
    return undefined;
  }, [industryField]);

  const updateSurveyModel = (key: KeyOfSurveyModel, value: any) => {
    setSurveyModel((prev: any) => {
      return { ...prev, [key]: value };
    });
  };

  const handleContinueToOnixExpress = async () => {
    setSubmitted(true);
    if (
      surveyModel.companySize === undefined ||
      surveyModel.equipmentSize === undefined ||
      surveyModel.industries === undefined ||
      (!surveyModel.industries && !surveyModel.otherIndustry)
    ) {
      return;
    }

    if (surveyModel) {
      const result = await CommonService.saveContactSurveyForm(surveyModel);
      if (result === true) {
        onDismiss && onDismiss();
      }
    }
  };

  return (
    <CommonModal
      isOpen={true}
      showCloseButton={false}
      header={
        <div className="mx-[12px] my-5 w-full">
          <div className="flex items-center justify-center my-3 w-full">
            <img src={getCompanyLogo(logoModel)} alt="" className="w-[100px] md:w-[157px] h-full object-contain block" />
          </div>
          <p className="text-xl font-600">{translate('CaptionResource.TellUsYourself')}</p>
          <p className="text-[12px]">{translate('CaptionResource.TellUsYourselfDescription')}</p>
        </div>
      }
      children={
        <div className="flex flex-col gap-3 xl:w-[660px] px-3 py-6">
          <TextField
            label={translate('CaptionResource.WhatYourRole')}
            value={surveyModel.yourRole}
            maxLength={50}
            onChange={(_, newValue) => updateSurveyModel('yourRole', newValue)}
          />
          {renderIndustryField()}
          <Dropdown
            label={translate('CaptionResource.HowManyPeopleWorkWithEquipment')}
            options={orgSizeOptions}
            onChange={(_, option) => {
              if (option) {
                updateSurveyModel('companySize', option.key);
              }
            }}
            selectedKey={surveyModel.companySize}
            required
            errorMessage={surveyModel.companySize === undefined && submitted ? translate('CaptionResource.Required') : ''}
          ></Dropdown>
          <Dropdown
            label={translate('CaptionResource.HowManyEquipmentCompanyHave')}
            options={numberEquipmentSizeOptions}
            onChange={(_, option) => {
              if (option) {
                updateSurveyModel('equipmentSize', option.key);
              }
            }}
            selectedKey={surveyModel.equipmentSize}
            required
            errorMessage={surveyModel.equipmentSize === undefined && submitted ? translate('CaptionResource.Required') : ''}
          ></Dropdown>
          <div className="flex flex-col gap-3 mt-2">
            <PrimaryButton
              text={translate('CaptionResource.ContinueToOnixExpress')}
              className="font-400 mr-0"
              onClick={handleContinueToOnixExpress}
            />
            <LinkButton
              className="text-center"
              onClick={() => {
                onDismiss && onDismiss();
              }}
            >
              {translate('CaptionResource.SkipForNow')}
            </LinkButton>
          </div>
        </div>
      }
      footer={
        <div className="w-full text-center text-mini">
          {`${translate('CaptionResource.PoweredBy')} `}
          <a onClick={() => {}} href="https://www.onix.com" className="font-medium underline text-primarypurple" target="blank">
            Onix Work
          </a>
        </div>
      }
      footerClass="py-3"
      footerGreyOut={false}
      onDismiss={onDismiss}
      styles={modalStyle}
    />
  );
};

export default TellUsSurvey;
